@charset "UTF-8";

.inline-link,
.link {
  display: inline-block;
  padding-right: 1.5em;
  position: relative;
  text-decoration: none;

  &:before {
    content: '\f0da';
    font-family: 'FontAwesome';
    margin-right: .5em;
  }

  &:after {
    content: ' ';
    font-family: 'FontAwesome';
    margin-left: .5em;
  }
  // ページ内リンク
  &[href^="#"]:before {
    content: '\f0d7';
  }
  // 外部リンク
  &[target^="_blank"]:after {
    content: '\f08e';
  }
  // ファイルリンク
  &[href$=".doc"]:after,
  &[href$=".docx"]:after,
  &[href$=".xls"]:after,
  &[href$=".xlsx"]:after,
  &[href$=".pdf"]:after {
    content: '\f016';
  }
  // 画像ファイル
  &[href$=".png"]:after,
  &[href$=".gif"]:after,
  &[href$=".jpeg"]:after,
  &[href$=".jpg"]:after {
    content: '\f03e';
  }
  // メール
  &[href^="mailto"]:after {
    content: '\f003';
  }
}

.inline-link {
  padding-left: 0;

  &:before {
    display: none;
  }
}

.img-link {
  background-color: $color-dark-bg;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  img {
    height: auto;
    max-width: 100%;
    transition: all .5s ease-in-out;
    vertical-align: bottom;
  }

  &:after {
    color: $color-white;
    content: '\f08e';
    font-family: 'FontAwesome';
    position: absolute;
    right: 10px;
    top: 5px;
  }

  &:hover {
    img {
      opacity: .9;
      transform: scale(1.1, 1.1);
    }
  }
}

.link-detail {
  color: $color-text;
  text-decoration: none;
  display: inline-block;
  border-bottom: solid 1px transparent;
  &:before {
    content: ">";
    margin-right: .5em;
  }
  &:hover {
    border-bottom: solid 1px $color-text;
  }
}
