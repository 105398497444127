@charset "UTF-8";
@import 'base/mixins';
@import 'settings';
// base
@import 'base/normalize';
@import 'base/base';
@import 'base/form';
// WordPress
//@import 'wordpress/wordpress';
// layout
@import 'layout/layout';
@import 'layout/container';
@import 'layout/grid-legacy';
// navigation
@import 'nav/link';
@import 'nav/header-nav';
@import 'nav/breadcrumb';
@import 'nav/pagenate';
@import 'nav/button';
// site
@import 'site';
@import 'contact';
@import 'typo';
// page
@import 'frontpage';
@import 'page';
@import 'page-story';
@import 'page-brand';
@import 'page-product';
// adjust
@import 'base/adjust';
// print
@import 'print';

//
// 一時的に非表示にするために･･･
//
// ヘッダーのオンラインショップへのリンク
.btn-shop {
//  display: none;
}
//トップページのブランド別オンラインショップへのリンク
.box-brand .brand-info .brand-link .link-shop_top {
//  display: none;
}
//言語切り替えボタン（TOPページ・法人のお客様へページ及びスマホメニュー）
.lang-menu,
.lang-menu_sp,
.lang-menu_page-top {
  display: none;
}
//商品詳細ページのオンラインショップへのリンク
.link-shop {
//  display: none;
}
