@charset "UTF-8";
// 商品一覧

.sec-product-box {
  padding: 4rem 0;
}
.sec-product-box_hitotoe {
  padding: 4rem 0;
  background-color: $color-hitotoe_bg;
}
.sec-product-box_danke {
  padding: 4rem 0;
  background-color: $color-danke_bg;
}
.sec-product-box_ntd {
  padding: 4rem 0;
  background-color: $color-ntd_bg;
}
.ttl-product_brand {
  margin: 0 0 4rem 0;
  text-align: center;
  padding: 0 50px;
  @media (min-width: $screen-sm-min) {
    padding: 0;
  }
}
.ttl-product_brand-text {
  @include fontsize(24);
  margin: 0 0 4rem 0;
  text-align: center;
  padding: 0 50px;
  @media (min-width: $screen-sm-min) {
    padding: 0;
  }
}

.row-flex {
  @include clearfix();
  display: flex;
  flex-wrap: wrap;
  margin-left: ($gutter / -4);
  margin-right: ($gutter / -4);
  &:before,
  &:after {
    display: inline-block;
  }
}
.col-product {
  @include clearfix();
  position: relative;
  min-height: 1px;
  padding-left: ($col-gutter / 4);
  padding-right: ($col-gutter / 4);
  padding-bottom:20px;
  width: 100%;
  float: left;
  img {
    height: auto;
    max-width: 100%;
  }
  @media (min-width: $screen-sm-min) {
    padding-left: ($col-gutter / 2);
    padding-right: ($col-gutter / 2);
    width:50%;
    padding-bottom:40px;
  }
  @media (min-width: $screen-md-min) {
    width:33.33%
  }
}

.card-product {
  position: relative;
  display: block;
  border: solid 1px $color-gray_dark_light;
  @include fontsize(12);
  text-decoration: none;
  color:$color-text;
//  margin-bottom: $col-gutter / 2;
  padding: 2em 1em 1em 1em;
  .card-image {
    text-align: center;
    margin-bottom: .5em;
    img {
      vertical-align: bottom;
    }
  }
  .card-term,
  .card-title {
    line-height: 1.2;
  }
  &:hover {
    opacity: .65;
  }
  @media (min-width: $screen-sm-min) {
    @include fontsize(14);
//    height: auto;
    height: 100%;
//    margin-bottom: $col-gutter;
    padding: 2em 1em 1em 1em;
    .card-image {
      margin-bottom: 1em;
    }
    .card-term,
    .card-title {
      text-align: center;
    } 
  }
}

// 製品詳細
.ttl-product {
  @include fontsize(22);
  font-family: $font-family-serif;
  text-align: center;
  margin: 0 0 1em 0;
  &.hitotoe {
    color:$color-hitotoe;
  }
  &.danke {
    color:$color-danke;
  }
  &.ntd {
    color:$color-ntd;
  }
  @media (min-width: $screen-sm-min) {
    @include fontsize(30);
  }
}
.lead-product {
  @include fontsize(17);
  margin: 2em 0;
  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}
.sales-news {
  color:#cc0000;
  font-weight: bold;
  @media (min-width: $screen-sm-min) {
    text-align: center;
    @include fontsize(15);
  }
}
.sales-period {
  font-weight: bold;
  .title {
    padding: .5em 1em;
    background-color: $color-gray;
    margin-right: .5em;
  }
  @media (min-width: $screen-sm-min) {
    text-align: center;
    @include fontsize(15);
  }
}
.product-news-text {
  color:#cc0000;
  @media (min-width: $screen-sm-min) {
    @include fontsize(15);
  }
}
.product-one {
  &:first-child {
    border-top: 0;
  }
  border-top: solid 1px $color-gray_dark_light;
  margin-bottom: 20px;
  padding-top: 20px;
  .product-name {
    @include fontsize(17);
    font-weight: bold;
    color:$color-hitotoe;
    &.hitotoe {
      color:$color-hitotoe;
    }
    &.danke {
      color:$color-danke;
    }
    &.ntd {
      color:$color-ntd;
    }
  }
  .product-cal {
    @include fontsize(14);
    font-weight: normal;
    color:$color-text;
  }
  .product-text {
    @include fontsize(14);
  }
  @media (min-width: $screen-sm-min) {
    .product-cal {
      @include fontsize(15);
      display: inline;
      margin-left: 1em;
    }
    .product-text {
      @include fontsize(15);
    }
  }
}

.product-hako {
  @include clearfix();
  &:first-child {
    border-top: 0;
  }
  border-top: solid 1px $color-gray_dark_light;
  margin-bottom: 2rem;
  padding-top: 2rem;
  .hako-info {
    .hako-name {
      @include fontsize(18);
      font-weight: bold;
      line-height: 1;
      margin: 0 0 .5em 0;
    }
    .hako-allergy,
    .hako-text {
      @include clearfix();
      margin-bottom: 1em;
      .title {
        padding: 0 1em;
        margin-bottom: .5em;
        font-weight: bold;
        background-color: $color-hitotoe_medium;
        &.hitotoe {
          background-color: $color-hitotoe_medium;
        }
        &.danke {
          background-color: $color-danke_medium;
        }
        &.ntd {
          background-color: $color-ntd_medium;
        }
      }
    }
  }
  .hako-image {
    img {
      max-width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  @media (min-width: $screen-sm-min) {
    .hako-info {
      width:450px;
      float:left;
      .hako-allergy,
      .hako-text {
        @include clearfix();
        margin-bottom: 1em;
        .title {
          padding: 0;
          float:left;
          width:115px;
          text-align: center;
          background-color: $color-hitotoe_medium;
        }
        .text {
          float:right;
          width:320px;
        }
      }
    }
    .hako-image {
      width:227px;
      float:right;

    }
  }
  @media (min-width: $screen-md-min) {
    .hako-info {
      width:500px;
      .hako-allergy,
      .hako-text {
        .text {
          width:370px;
        }
      }
    }
  }
}

.product-days {
  @include clearfix();
  margin-top: 3rem;
  text-align: right;
  .product-day {
    display: inline-block;
    width: 115px;
    &:first-child {
      margin-right: 10px;
    }
    .title {
      text-align: center;
      background-color: $color-white;
      
    }
    .text {
      text-align: center;
    }
  }
}

.txt-new {
  color:$color-accent;
  margin-right: .5em;
}
.lbl-new {
  position: absolute;
  top:.5em;
  left:14px;
  line-height: 1.4;
  @include fontsize(12);
  display:inline-block;
  background-color: #cc0000;
  color:$color-white;
  padding: 0 .25em;
  letter-spacing: .25em;
  text-indent: .25em;
}
.lbl-new_detail {
  line-height: 1.4;
  @include fontsize(18);
  display:inline-block;
  background-color: #cc0000;
  color:$color-white;
  padding: 0 .25em;
  letter-spacing: .25em;
  text-indent: .25em;
}
.lbl-new_detail-small {
  line-height: 1.4;
  @include fontsize(12);
  display:inline-block;
  background-color: #cc0000;
  color:$color-white;
  padding: 0 .25em;
  letter-spacing: .25em;
  text-indent: .25em;
}
.lbl-renew {
  position: absolute;
  top:.5em;
  left:14px;
  line-height: 1.4;
  @include fontsize(12);
  display:inline-block;
  background-color: #f4f414;
  color:#e81616;
  padding: 0 .25em;
  letter-spacing: .05em;
  text-indent: .25em;
}
.lbl-renew2 {
  position: absolute;
  top:.5em;
  left:75px;
  line-height: 1.4;
  @include fontsize(12);
  display:inline-block;
  background-color: #f4f414;
  color:#e81616;
  padding: 0 .25em;
  letter-spacing: .05em;
  text-indent: .25em;
}
.lbl-renew_detail {
  line-height: 1.4;
  @include fontsize(18);
  display:inline-block;
  background-color: #f4f414;
  color:#e81616;
  padding: 0 .25em;
  letter-spacing: 0;
  text-indent: 0;
}
.lbl-renew_detail-small {
  line-height: 1.4;
  @include fontsize(12);
  display:inline-block;
  background-color: #f4f414;
  color:#e81616;
  padding: 0 .25em;
  letter-spacing: .25em;
  text-indent: .25em;
}
@media all and (-ms-high-contrast:none){
  .lbl-new,
  .lbl-new_detail,
  .lbl-new_detail-small,
  .lbl-renew,
  .lbl-renew_detail,
  .lbl-renew_detail-small {
    font-family: 'Helvetica Neue' , Helvetica , Arial , "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
  }
}