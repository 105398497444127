@charset "UTF-8";

body {
  
}

//
// ヘッダー
//
.l-header {
  z-index: 500;
  position: fixed;
  width: 100%;
  padding: .5rem 0;
//  background : #fff;
  background : rgba(255, 255, 255, 0.85);
  @media (min-width: $screen-md-min) { 
    padding:2rem 0;
  }
}
.l-header_front {
  z-index: 500;
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  padding: .5rem 0;
  background : linear-gradient(0deg, rgba(238, 241, 241, .85) 0%, rgba(240, 243, 243, .85) 56.86%, rgba(248, 249, 249, .85) 86.29%, rgba(255, 255, 255, .85) 100%);
  @media (min-width: $screen-sm-min) { 
    position: relative;
    &.is-fixed {
      position: fixed;
    }
  }
  @media (min-width: $screen-md-min) { 
    padding:2rem 0;
  }
}
//
// コンテンツ
//
.l-main {
  padding-top: 53px; 
  @media (min-width: $screen-sm-min) { 
    padding-top: 139px; 
  }
  @media (min-width: $screen-md-min) { 
    padding-top: 181px; 
  }
}
.l-main_front {
  
}
//
// フッター
//
.l-footer {
  background-color: $color-gray_light;
  padding: 1rem 0;
  @media (min-width: $screen-sm-min) {
    padding: 3.4rem 0;
  }
}
// ページトップに戻るボタン
.btn-pagetop {
  bottom: 15px;
  color: $color-text;
  cursor: pointer;
  display: block;
  height: 33px;
  line-height: 1;
  opacity: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  transform: perspective(0);
  transition: transform .3s ease-in-out,background-color .3s ease-in-out;
  width: 33px;
  z-index: 100;

  &:before {
//    background-color: $color-gray_dark;
    color: $color-white;
    font-size:33px;
    font-family: 'FontAwesome';
//    content: '\f106';
    content: ' ';
    display: block;
    width:33px;
    height: 33px;
    background-image: url(../img/common/icon-top.png);
    background-size: cover;
  }

  &.visible {
    animation-duration: .5s;
    animation-name: fade-in;
    display: block;
    opacity: 1;
  }

  &:hover {
    transform: scale(1.2);
  }

}

@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

//
// base
//
// header
.header-text {
  background-color: $color-main;
  color: $color-white;
  font-size:0.77em;
  line-height: 1.2;
  margin: 0 0 5px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  @media (min-width: $screen-md-min) {
    line-height: 1;
    padding-top: 0;
    padding-bottom: 0;
    margin: 15px 0 10px 0;
    background-color: transparent;
    color: $color-text;
    width: $container-md;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

// privacypolicy
.btn-privacy {
  font-size: 0.9rem;
  display: block;
  background-color: #fff;
  text-decoration: none;
  width:280px;
  margin: 10px auto;
  padding: 8px 0;
  &:after {
    content: '\f08e';
    font-family: 'FontAwesome';
    margin-left: .5em;
  }
}
.privacy-link {
  display: none;
  @media (min-width: $screen-md-min) {
    display: inline-block
  }
  color:$color-text;
  text-decoration: none;
  &:before {
    content: '\f0da';
    font-family: 'FontAwesome';
    margin-right: .5em;
  }
}
.hidden {
  display: none;
}
.box-privacy {
  background-color: #fff;
  padding: 1em;
  @media (min-width: $screen-md-min) {
    padding: 3em;
  }
}
.box-contact {
  padding: 0 .5em;
  border: solid 1px #ccc;
}

//
// map
//
#map04,
#map03,
#map02,
#map01 {
  margin-top: 10px;
  height: 300px;
  width: 100%;
  background-color: #ccc;
}

// footer
.copyright {

}

.googlemaps {
  position: relative;
  height: 300px;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
// adjust
img {
  max-width: 100%;
  height: auto;
}
.sp-show {
  display: inherit;
}
.sp-none {
  display: none;
}
@media (min-width: $screen-sm-min) {
  .sp-show {
    display: none;
  }
  .sp-none {
    display: inherit;
  } 
}