@charset "utf-8";
$colum-num:12 !default;
$col-gutter:30px !default;

// カラムの定義
@mixin make-col() {
  @include clearfix();
  position: relative;
  min-height: 1px;
  padding-left: ($col-gutter / 4);
  padding-right: ($col-gutter / 4);
  width: 100%;
  float: left;

  img {
    height: auto;
    max-width: 100%;
  }
}

// .col[0-9] $colum-num を最大数
@for $i from 1 through $colum-num {
  .col-#{$i} {
    @include make-col();
    width: 100% / $colum-num * $i;
  }
}

// カラム幅取得
@mixin col-width($num) {
  width: 100% / $colum-num * $num;
}

// xs-size
.row {
  @include clearfix();
    margin-left: ($gutter / -4);
  margin-right: ($gutter / -4);
}
.row_full {
  @include clearfix();
  margin-left: ($gutter / -2);
  margin-right: ($gutter / -2);
}

@for $i from 1 through $colum-num {
  .col-lg-#{$i},
  .col-md-#{$i},
  .col-sm-#{$i},
  .col-xs-#{$i} {
    @include make-col();
  }

  .col-xs-#{$i} {
    width: 100% / $colum-num * $i;
  }
}
// sm-size
@media (min-width: $screen-sm-min) {
  .row {
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
  }

  @for $i from 1 through $colum-num {
    .col-sm-#{$i} {
      width: 100% / $colum-num * $i;
    }

    .col-lg-#{$i},
    .col-md-#{$i},
    .col-sm-#{$i},
    .col-xs-#{$i} {
      padding-left: ($col-gutter / 2);
      padding-right: ($col-gutter / 2);
    }
  }
}
// md-size
@media (min-width: $screen-md-min) {
  @for $i from 1 through $colum-num {
    .col-md-#{$i} {
      width: 100% / $colum-num * $i;
    }
  }
}
// lg-size
@media (min-width: $screen-lg-min) {
  @for $i from 1 through $colum-num {
    .col-lg-#{$i} {
      width: 100% / $colum-num * $i;
    }
  }
}
