@charset "utf-8";
//
// SIERRA MIXINS
//
$font-base: 14 !default;
// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
  }
}
//
@mixin fontsize($size: 14, $base: $font-base) {
    font-size: ($size / $font-base) * 1rem;
}