@charset "UTF-8";

.form-contents {
  input {
    &[type=text],
    &[type=password],
    &[type=email],
    &[type=tel],
    &[type=number] {
      appearance: none;
      background-color: $color-white;
      background-image: none;
      border: 1px solid $color-light-border;
      color: $color-text;
      display: inline-block;
      line-height: 1;
      max-width: 100%;
      padding: .5em;
      width: auto;
    }

    &:focus {
      background-color: $color-focus-bg;
      border: solid 1px $color-focus-border;
      outline: none;
    }

    &.error {
      background-color: $color-error-bg;
      border: solid 1px $color-error-border;
      color: $color-error-text;
    }

    &.sizefull {
      width: 100%;
    }

    // Disabled and read-only inputs
    &[disabled],
    &[disabled]:hover,
    &[readonly] {
      background-color: $color-disable-bg;
      border-color: $color-disable-border;
      color: $color-disable-text;
      opacity: 1;
    }

    &[disabled] {
      cursor: not-allowed;
    }
  }

  textarea {
    background-color: $color-white;
    border: 1px solid $color-light-border;
    color: $color-text;
    display: block;
    height: auto;
    padding: .25em .5em;
    width: 100%;

    &:focus {
      background-color: $color-focus-bg;
      border: solid 1px $color-focus-border;
      outline: none;
    }
  }

  select {
    appearance: none;
    background-color: $color-white;
    background-image: url('../img/common/arrow.svg');
    background-position: right 50%;
    background-repeat: no-repeat;
    border: 1px solid $color-disable-border;
    cursor: pointer;
    display: block;
    overflow: visible;
    padding: .25em 3em .25em .5em;
    position: relative;

    option {
      color: $color-text;
    }

    &:focus {
      background-color: $color-focus-bg;
      border: solid 1px $color-focus-border;
      outline: none;
    }

    &::-ms-expand {
      display: none;
    }
  }

  // メールフォーム チェック表示領域
  .form-check {
    background-color: $color-error-bg;
    color: $color-error-text;
    display: none;
    margin: .5em 0;
    padding: .5em 1em;

    &.show {
      display: block;
    }
  }

  //  送信内容確認ボタン enable
  .confirm-btn-on {
    opacity: 1;
  }
  // 送信内容確認ボタン disable
  .confirm-btn-off {
    opacity: .5;
  }
  // 必須
  .must {
    background-color: $color-must;
    color: $color-white;
    font-size: 80%;
    line-height: 1;
    margin: 0 .5em;
    padding: .1em .5em;
  }

}
