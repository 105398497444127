//
// - Base ----
//
// -- Base Font -----
//
$font-base: 14;
$font-size-base: 14px;
$line-height-base: 1.8;
// Sans-serif
$font-family-sans-serif: 'Helvetica Neue' , Helvetica , Arial , "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif !default;
//$font-family-sans-serif: 'Helvetica Neue' , Helvetica , Arial , "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif !default;
// Serif
$font-family-serif: 'Times New Roman', 'Sawarabi Mincho', '游明朝', YuMincho, 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif !default;
// Default font set
$font-family-base:$font-family-sans-serif;
//$font-family-base:$font-family-serif;

//
// - Layout -----
//
// -- Container Size -----
// gutter-size
//$gutter:24px;
$gutter:40px;
$colum-num:12;
$col-gutter: $gutter;
// break-points
$screen-sm-min:768px;
$screen-md-min:1024px;
$screen-lg-min:1300px;
// contaier-width
//$container-sm:750px;
//$container-md:984px;
//$container-lg:1170px;
$container-sm:750px;
$container-md:1000px;
$container-lg:1240px;

//
// - Color -----
//
$color-main: #6DB162;
$color-main_light: #B8D6B2;
$color-main_superlight: #F1F7EF;
$color-sub: #808080;
$color-accent: #C4375B;
$color-text:  #333;
$color-gray: #ccc;
$color-gray_dark: #666;
$color-gray_dark_light: #999;
$color-gray_vision: #D2D2D2;
$color-gray_vision_odd: #E6E6E6;
$color-gray_medium: #EFF2F2;
$color-gray_light: #F5F7F7;
$color-white: #fff;
$color-light-bg: #fafafa;
$color-light-border: #ccc;
$color-dark-bg: #222;
$color-dark-border: #000;
$color-hitotoe: #902727;
$color-hitotoe_bg: #F4E9E9;
$color-hitotoe_medium: #E6CDCD;
$color-danke: #FA4616;
$color-danke_bg: #FEECE7;
$color-danke_medium: #FFD2C8;
//$color-ntd: #C09A5E;
$color-ntd: #442C16;
$color-ntd_bg: #F9F5EF;
$color-ntd_medium: #E6D7BE;
$color-story-history:#C4A667;
// typo
$color-mark:  #ff0;
// form
$color-focus-bg: #fdfdff;
$color-focus-border: #9cf;
$color-error-bg: #fff9f9;
$color-error-border: #dec7c7;
$color-error-text: #bc1313;
$color-disable-bg: #eee;
$color-disable-border: #ccc;
$color-disable-text: #bbb;
$color-must: #bc1313;
// link
$color-link:#147bcf;
$color-link-hover:#147bcf;
// button
$color-btn-bg:#efefef;
$color-btn-border:#ccc;
$color-btn-hover:#ddd;
// custom
$color-home:#1897f2;
$color-home_light:#d6f0d9;
$color-work:#39b54a;
$color-company:#f15a24;