@charset "UTF-8";
//
// パンくず
//
.breadcrumb {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 0;

  li {
    display: inline;

    a {
      color: $color-text;
    }

    &:before {
      content: '\f105';
      font-family: 'FontAwesome';
      margin-left: .5em;
      margin-right: .5em;
    }

    &:first-child:before {
      content: '';
      margin-left: 0;
    }
  }
}
