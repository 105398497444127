@charset "UTF-8";

@page {
//  size: A4;
//  margin: 10mm;
}
@media print {
  body {
    display: block;
    overflow: hidden;
    width: 1200px;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 0 0;
  }
  .container {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }

}
