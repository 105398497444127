@charset "UTF-8";

// ページナビ
.pagenate,
.nav-links,
.wp-pagenavi {
  @include clearfix();
  clear: both;
  padding: 10px 0;
  text-align: center;

  .first:before {
    content: '\f100';
    font-family: 'FontAwesome';
    margin: 0 .5em 0 0;
  }

  .last:after {
    content: '\f101';
    font-family: 'FontAwesome';
    margin: 0 0 0 .5em;
  }

  .prev:before {
    content: '\f104';
    font-family: 'FontAwesome';
    margin: 0 .5em 0 0;
  }

  .next:after {
    content: '\f105';
    font-family: 'FontAwesome';
    margin: 0 0 0 .5em;
  }

  a,
  .current {
    background-color: $color-white;
    border: solid 1px $color-text;
    box-sizing: border-box;
    color: $color-text;
    display: inline-block;
    line-height: 1;
    margin: .05em;
    padding: .5em;
    text-decoration: none;
    transition: background-color .5s linear, color .2s linear, border-color .2s linear;
  }

  a:hover {
    background-color: $color-text;
    color: $color-white;
  }

  .current {
    background-color: $color-text;
    border: solid 1px $color-text;
    color: $color-white;
  }

  .first,
  .last,
  .current.page-numbers,
  .page-numbers {
    display: none;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
    }
  }
}

// ページナビ
.page-links {
  @include clearfix();
  clear: both;
  padding: 10px 0;
  text-align: center;

  .page-numbers {
    background-color: $color-text;
    border: solid 1px $color-text;
    color: $color-white;
    display: inline-block;
    line-height: 1;
    margin: .05em;
    padding: .5em;
  }

  a .page-numbers {
    background-color: $color-white;
    border: solid 1px $color-text;
    color: $color-text;
    display: inline-block;
    text-decoration: none;
    transition: background-color .5s linear, color .2s linear, border-color .2s linear;

    &:hover {
      background-color: $color-text;
      color: $color-white;
    }
  }
}

// ページナビ
.page-navi {
  display: flex;
  flex-flow: row;
  padding: 2em 0;

  .page-next,
  .page-prev {
    display: flex;
    padding-right: .5em;
    width: 50%;

    a {
      background-color: $color-white;
      border: solid 1px $color-text;
      color: $color-text;
      display: block;
      padding: 1em 1.5em;
      position: relative;
      text-decoration: none;
      width: 100%;

      .title {
        display: block;
        font-weight: bold;
        padding-left: 1em;
      }

      &:before {
        content: '\f053';
        display: inline-block;
        font-family: 'FontAwesome';
        left: 1em;
        position: absolute;
        top: 1em;
      }

      &:hover {
        background-color: $color-text;
        color: $color-white;
      }
    }
  }

  .page-next {
    padding: 0 0 0 .5em;

    a {
      padding: 1em 1.5em;

      .title {
        padding-left: 0;
      }

      &:before {
        content: '\f054';
        left: inherit;
        right: 1em;
      }
    }
  }
}
