@charset "UTF-8";
// 取り扱いブランド

.title-story {
  @include fontsize(22);
  font-family: $font-family-serif;
  border: solid 1px $color-text;
  line-height: 1;
  text-align: center;
  padding: .8em 0;
}

.lead-story {
  @include fontsize(15);
  margin: 3rem 0;
  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}

.sec-brand-box {
  position: relative;
  padding-bottom: 5rem;
//  margin-bottom: 10rem;
  &:before {
    @media (min-width: $screen-sm-min) {
      position: absolute;
      content: " ";
      display: block;
      width: 62%;
      height: 500px;
      background-color: $color-gray_light;
      bottom:0;
      left: 0;
    }
  }
  @media (min-width: $screen-sm-min) {
    margin-bottom: 10rem;
  }
}
.image-brand-box {
  margin-bottom: 2rem;
}
.image_sub-brand-box {
  text-align: center;
}
.logo-brand-box {
    text-align: center;
}
.catch-brand-box_hitotoe {
  @include fontsize(16);
  font-family: $font-family-serif;
  color:$color-hitotoe;
    text-align: center;
  @media (min-width: $screen-sm-min) {
    @include fontsize(19);
  }
}
.catch-brand-box_danke {
  @include fontsize(16);
  font-family: $font-family-serif;
  color:$color-danke;
    text-align: center;
  @media (min-width: $screen-sm-min) {
    @include fontsize(19);
  }
}
.catch-brand-box_ntd {
  @include fontsize(16);
  font-family: $font-family-serif;
  color:$color-ntd;
    text-align: center;
  @media (min-width: $screen-sm-min) {
    @include fontsize(19);
  }
}
.text-brand-box {
  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}

.brand-menu {
  list-style-type: none;
  margin: 0 0 1rem;
  padding: 0;
  text-align: center;
  .menu-item {
    display: inline-block;
    margin: 1rem 0;
    line-height: 1;
    border-right: solid 1px $color-text;
    a {
      display: block;
      text-decoration: none;
      color:$color-text;
      letter-spacing: .1em;
      padding: 0 1em;
      .eng {
        display: block;
        text-align: center;
        margin-bottom: 6px;
      }
      .jp {
        @include fontsize(12);
        display: block;
        text-align: center;
        letter-spacing: 0;
      }
      &:hover {
        color:$color-link;
      }
    }
    &.brand-link {
      border-right: 0;
    }
  }
  @media (min-width: $screen-sm-min) {
    text-align: right;
    .menu-item a {
      padding: 0 2rem;
    }
    .menu-item.brand-link a {
      padding-right: 0;
    }
  }
}


