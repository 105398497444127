@charset "UTF-8";
// gutter-size
$gutter:30px !default;
// break-points
$screen-sm-min:768px !default;
$screen-md-min:992px !default;
$screen-lg-min:1200px !default;
// contaier-width
$container-sm:750px !default;
$container-md:970px !default;
$container-lg:1170px !default;

.container {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
}

.container_small {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-sm;
  }
}

.container_medium {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-md;
  }
}

.container_product {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: 1140px;
  }
}

.container_product-one {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
  position: relative;
  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: 800px;
  }
  @media (min-width: $screen-lg-min) {
    width: 800px;
  }
}

.container-fluid {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  padding-left: ($gutter / 2);
  padding-right: ($gutter / 2);
}
