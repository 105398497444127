@charset "UTF-8";
.sec-slide {
  background-color: red;
}
//
// top slide
//
.top-slide_section {
  position: relative;
  .slide-catch {
    @include fontsize(16);
//    background-color: rgba(0, 0, 0, 0.3);
    padding: 1em 0;
    position: absolute;
    top:100px;
    display: inline-block;
    text-align: right;
    color:#fff;
    font-family : $font-family-serif;
    line-height : 1.6;
    letter-spacing : .3em;
    text-indent: .3em;
    text-shadow: 0px 0px 3px #333; 
    @media (min-width: $screen-md-min) {
      top:180px;
      text-align: right;
      @include fontsize(28);
    }
  }
}
.top-slide_box {
  margin: 0 auto;
}
.top-slide {
  width:100%;
  height: 260px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  img {
    margin: 0 auto;
  }
  @media (min-width: $screen-md-min) {
//    height: 610px;
    height: 500px;
  }
}
.slide01 {
  background-image: url(../img/slide/slide01.jpg);
  background-position: center center;
}
.slide02 {
  background-image: url(../img/slide/slide02.jpg);
}
.slide03 {
  background-image: url(../img/slide/slide03.jpg);
}
.slide04 {
  background-image: url(../img/slide/slide04.jpg);
}
.slide05 {
  background-image: url(../img/slide/slide05.jpg);
}

//
// セクション
//
.sec-brand {
  display: block;
  padding: 2rem 0;
  @media (min-width: $screen-sm-min) {
    padding: 0;
  }
}
.sec-news {
  background-image: url(../img/top/bg-news_front.jpg);
  background-size: cover;
  display: block;
  padding: 2rem 0;
  @media (min-width: $screen-md-min) {
    padding: 5rem 0;
  }
}
.sec-company {
  display: block;
  padding: 5rem 0;
}
//
// ブランド
//
.box-brand{
  .brand-image {
    width: 100%;
    height: 10rem;
    background-size: cover;
    background-position: center center;
//    background-attachment: fixed;
    &.tests {
      order:2;
    }
    img {
      max-width: 100%;
      height: auto;
      vertical-align: bottom;
    }
  }
  .brand-info {
    &.tests {
      order:1;
    }
    padding: 2rem 0;
    .brand-logo {
      text-align: center;
      img {
        max-width: 160px;
        height: auto;
      } 
    }
    .brand-catch {
      @include fontsize(16);
      text-align: center;
      font-family: $font-family-serif;
      letter-spacing: .1em;
      text-indent: .1em;
    }
    .brand-link {
      text-align: center;
      .link-brand_top,
      .link-shop_top {
        @include fontsize(12);
        display: inline-block;
        color:$color-text;
        line-height: 1;
        text-decoration: none;
        text-indent: .1em;
        padding: 0 .5em;
        small {
          font-weight: normal;
          letter-spacing: 0;
          padding: .5em;
          @include fontsize(11);
          color:$color-gray_dark;
          display: block;
        }
        &:hover {
          color:$color-link;
          small {
            color:$color-link;
          }
        }
      }
      .link-shop_top {
        border-right: solid 1px $color-text;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
     display: flex;
    .brand-info,
    .brand-image {
      width: 50%;
      height: auto;
    }
    .brand-image {
//      width: 100%;
//      height: 10rem;
      background-size: auto;
      background-size: cover;
      background-position: center center;
//      background-attachment: fixed;
    }
    .brand-info {
      padding: 8rem 0;
      .brand-logo {
        img {
          max-width: 100%;
        } 
      }
      .brand-catch {
        @include fontsize(20);
      }
      .brand-link {
        .link-brand_top,
        .link-shop_top{
          @include fontsize(14);
          letter-spacing: .1em;
          padding: 0 2em;
        }
      }
    }
  }
}

//
// 最新情報
//
.title-serif_front {
  font-family: $font-family-serif;
  font-weight: normal;
  @include fontsize(17);
}
.news-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  .list-item {
    position: relative;
    border-bottom: solid 1px $color-gray;
    padding: 1em 0;
    .post-date {
      display: block;
      position: absolute;
      top:1em;
      left:0;
    }
    .post-title {
      display: block;
      margin-left: 6em;
      a{
        color: $color-text;
        &:hover {
          text-decoration: none;
        }
      }
      
    }
  }
}

.title-business_front {
  @include fontsize(22);
  text-align: center;
  letter-spacing: .5em;
  text-indent: .5em;
}
.lang-menu {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  .menu-item {
    display: inline-block;
    a {
      display: block;
      @include fontsize(12);
      text-decoration: none;
      color:$color-text;
      border: solid 1px $color-gray_dark;
      line-height: 1;
      padding: 1em 1.5em;
      &:hover {
        background-color: $color-gray_dark;
        color:$color-white;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    position: absolute;
    top:22px;
    right: 0;  
  }
}

.pickup-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  .menu-item {
    display: inline-block;
    width:20%;
    margin: 1rem;
    a {
      display: block;
      @include fontsize(15);
      text-decoration: none;
      color:$color-text;
      line-height: 1;
      .img-menu {
        display: block;
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 2em;
        img {
          vertical-align: bottom;
        }
      }
      .eng-menu {
        display: block;
        letter-spacing: .1em;
        text-indent: .1em;
        @include fontsize(15);
      }
      .jp-menu {
        display: block;
        @include fontsize(10);
        color:$color-gray_dark;
        padding: .5em 0;
      }
      &:hover {
        opacity: .6;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-align: center;
      .menu-item {
        display: inline-block;
        width:25%;
        a {
          display: block;
          @include fontsize(15);
          text-decoration: none;
          color:$color-text;
          line-height: 1;
          .img-menu {
            display: block;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 2em;
          }
          .eng-menu {
            display: block;
            letter-spacing: .1em;
            text-indent: .1em;
            @include fontsize(15);
          }
          .jp-menu {
            display: block;
            @include fontsize(12);
            color:$color-gray_dark;
            padding: .5em 0;
          }
          &:hover {
            opacity: .6;
          }
        }
      } 
  }
    @media (min-width: $screen-md-min) {
      .menu-item {
        width: auto;
        margin: 2rem;
      }
  }
}

// sns btn
.sns-menu {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  .menu-item {
    display: inline-block;
    margin: 1em;
    a {
      display: block;
      width: 33px;
      height: 33px;
      text-decoration: none;
      color:$color-text;
      background-color: $color-gray_dark;
      line-height: 1;
      &:hover {
        background-color: $color-gray;
      }
    }
  }
}

// foot-menu
.foot-menu {
  display: none;
  @media (min-width: $screen-sm-min) {
    display: block;
    @include fontsize(12);
    text-align: center;
    list-style-type: none;
    margin: 1em 0;
    padding: 0;
    .menu-item {
      display: inline-block;
      margin: 1em;
      a {
        display: block;
        text-decoration: none;
        color:$color-gray_dark;
        line-height: 1;
        padding: .5em .5em;
        border-bottom: solid 1px transparent;
        &:hover {
          border-bottom: solid 1px $color-gray_dark;
        }
      }
      &.current a {
        border-bottom: solid 1px $color-gray_dark;
      }
    }
  }
}
.page-business .foot-menu .menu-item-business a,
.page-home .foot-menu .menu-item-home a,
.page-brand .foot-menu .menu-item-brand a,
.page-story .foot-menu .menu-item-story a,
.page-product .foot-menu .menu-item-product a,
.page-recruit .foot-menu .menu-item-recruit a,
.page-contact .foot-menu .menu-item-contact a {
  border-bottom: solid 1px $color-gray_dark;
}

// foot-menu_sub
.foot-menu_sub {
  @include fontsize(12);
  text-align: center;
  list-style-type: none;
  margin: 1em 0;
  padding: 0;
  .menu-item {
    display: inline-block;
    margin: 1em;
    a {
      display: block;
      text-decoration: none;
      color:$color-gray_dark;
      line-height: 1;
      padding: .5em .5em;
      border-bottom: solid 1px transparent;
      &:hover {
        border-bottom: solid 1px $color-gray_dark;
      }
    }
    &.current a {
      border-bottom: solid 1px $color-gray_dark;
    }
  }
  @media (min-width: $screen-sm-min) {
    margin: 3em 0;
  }
}
.page-sitemap .foot-menu_sub .menu-item-sitemap a,
.page-privacy .foot-menu_sub .menu-item-privacy a {
  border-bottom: solid 1px $color-gray_dark;
}


.copyright {
  @include fontsize(12);
  color:$color-gray_dark;
  @media (min-width: $screen-sm-min) {
    text-align: center;
  }
}