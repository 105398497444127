@charset "UTF-8";

html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
//
// ヘッダー
//
//.l-header { }
//
// コンテンツ
//
.l-main {
  flex: 1 1 auto;
}
//
// フッター
//
//.l-footer { }
