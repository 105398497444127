@charset "UTF-8";

html {
  box-sizing: border-box;
  font-size: $font-size-base;
  overflow-y: scroll;
  position: relative;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

body {
  font-family: $font-family-base;
  line-height: $line-height-base;
  color:$color-text;
}
//@media all and (-ms-high-contrast:none){
//  body {
//    font-family: 'Helvetica Neue' , Helvetica , Arial , "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
//  }
//}


:link {
  color:$color-link;
}
