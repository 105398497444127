@charset "UTF-8";

.header-nav {
  display: flex;
  @include clearfix();
  padding: 0;
  .site-logo {
    flex: 0 0 140px;
    text-align: center;
    margin: 0;
    z-index: 500;
  }
  @media (min-width: $screen-sm-min) {
    display: block;
    flex-direction: column;
    .site-logo {
      flex: 1;
      margin-bottom: 30px;
    }
  }
}

.main-menu {
  display: none;
  @media (min-width: $screen-sm-min) {
    display: block;
    position: inherit;
    .menu {
    @include clearfix();
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    .menu-item {
      a {
        position: relative;
        display: block;
        color:$color-text;
        text-decoration: none;
        text-align: center;
        line-height: 1;
        &:after {
          content: ' ';
          position: absolute;
          bottom:0;
          left: 0;
          display: block;
          width: 100%;
          height: 1px;
          background-color: $color-gray_dark;
        }
        .eng-menu{
          @include fontsize(15);
          letter-spacing: .1em;
          text-indent: .1em;
          display: block;
        }
        .jp-menu{
          @include fontsize(12);
          display: block;
          padding: .7em;
        }
        &:hover {
            &:after {
            height: 2px;
            background-color: $color-text;
          }
        }
      }
      &.current a:after {
        height: 2px;
        background-color: $color-text;
      }
    }
  }
  }
  @media (min-width: $screen-md-min) {
    .menu {
      .menu-item {
        width:12%;
      }
    }
  }
}
.page-business .main-menu .menu .menu-item-business a:after,
.page-home .main-menu .menu .menu-item-home a:after,
.page-brand .main-menu .menu .menu-item-brand a:after,
.page-story .main-menu .menu .menu-item-story a:after,
.page-product .main-menu .menu .menu-item-product a:after,
.page-recruit .main-menu .menu .menu-item-recruit a:after,
.page-contact .main-menu .menu .menu-item-contact a:after {
  height: 2px;
  background-color: $color-text;
}



.btn-shop {
  position: absolute;
  top:0;
  right: 52px;
  display: inline-block;
  background-image: url(../img/common/icon-cart.svg);
  background-color: #333;
  text-indent: -9999px;
  width:33px;
  height: 33px;
  @media (min-width: $screen-sm-min) {
    @include fontsize(16);
    text-indent: inherit;
    width: auto;
    height: auto;
    background-image:none;
    position: absolute;
    top:.5rem;
    right: 12px;
    display: inline-block;
    text-decoration: none;
    color:$color-white;
    background-color: $color-gray_dark;
    line-height: 1;
    padding: .5em 1em .5em 3em;
    vertical-align: middle;
    &:before {
      position: absolute;
      top:0;
      left:.75em; 
      display: inline-block;
      content: ' ';
      width: 33px;
      height: 33px;
      background-image: url(../img/common/icon-cart.svg);
    }
    &:hover {
      background-color: $color-gray;
    }
  }
}

.sp-menu {
  z-index: 300;
  position: fixed;
  top:0;
  right: 0;
  background-color: $color-gray_medium;
  height: 100%;
  width:100%;
  padding: 52px 1rem 0 1rem;
  transition: all 0.4s;
  overflow: auto;
  &.close {
    right: -100%;
  }
  .sp-menu-title {
    @include fontsize(15);
    font-weight: bold;
    margin: 0 0 .5em 0;
    position: relative;
  }
  .menu {
    @include clearfix();
    margin: 0 0 2rem 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-around;
    .menu-item {
      &:first-child {
        border-top: solid 1px $color-gray;  
      }
      border-bottom: solid 1px $color-gray;
      border-right: solid 1px $color-gray;
      border-left: solid 1px $color-gray;
      a {
        @include fontsize(15);
        position: relative;
        display: block;
        background-color: $color-white;
        color:$color-text;
        text-decoration: none;
        line-height: 1;
        letter-spacing: .1em;
        padding:1em;
        .jp-menu{
          @include fontsize(15);
          font-weight: bold;
          margin-right: .5em;
        }
        .eng-menu{
          @include fontsize(11);
          font-weight: bold;
          color:$color-main_light;
        }
        
        &:hover {
        
        }
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    right: -100%;
  }
}
.lang-menu_sp {
  text-align: center;
  position: absolute;
  top:0;
  right: 0;
  .menu-item {
    vertical-align: top;
    display: inline-block;
    margin: 0 .1em;
    a {
      display: block;
      @include fontsize(10);
      text-decoration: none;
      color:$color-text;
      border: solid 1px $color-gray;
      background-color: #fff;
      line-height: 1;
      padding: .6em 1em;
      &:hover {
        background-color: $color-gray_dark;
        color:$color-white;
      }
    }
    &.current a{
      background-color: $color-gray_dark;
      color:$color-white;
    }
  }
}


.menu-btn {
  z-index: 1000;
//  position: fixed;
  position: absolute;
  top:2px;
  right:12px;
  width:40x;
  height:40x;
  padding-top:0;
  font-size:10px;
  line-height: 1;
  text-align: center;
//  background-color:$color-main;
  color:$color-main;
  cursor: pointer;
  transition: all 0.4s;
  &:before {
    display: block;
    font-size:20px;
    margin-bottom: 2px;
    font-family: 'FontAwesome';
    content:"\f0c9";
  }
  &:active {
    box-shadow: 0 0 0 #666;
    top:7px;
  }
  &.open {
    &:before {
      content:"\f00d";
    }
  }
  @media (min-width: $screen-sm-min) {
    display:none;
  }
}