@charset "UTF-8";
// 私たちの想い
.sec-page-head_story {
  position: relative;
  //background-color: $color-main;
  background-image: url(../img/story/bg-story.jpg);
  background-position: top center;
  background-size: contain;
  padding-top: 20%;
  margin-bottom: 1rem;
  .title-story {
    display: inline-block;
    @include fontsize(18);
    font-family: $font-family-serif;
    font-weight: normal;
    line-height: 1;
    margin: 0 0 1em 0;
    padding-bottom: .5em;
    padding-left:1em;
    padding-right: 1em;
    letter-spacing: .2em;
    text-align: center;
    text-indent: .2em;
    border-bottom: solid 1px $color-text;
  }
  .catch-story {
    @include fontsize(22);
    font-family: $font-family-serif;
    margin: 0 0 1em 0;
  }
  .text-story {
    @include fontsize(14);
  }
  .story-photo02,
  .story-photo01 {
    width: 45%;
    }
  @media (min-width: $screen-sm-min) { 
    background-size:auto;
    padding-top: 240px;
    margin-bottom: 1rem;
    min-height: 550px;
    text-align: center;
    .title-story {
      display: inline-block;
      @include fontsize(24);
      font-family: $font-family-serif;
      font-weight: normal;
      line-height: 1;
      margin: 0 0 1em 0;
      padding-bottom: .5em;
      letter-spacing: .2em;
      text-align: center;
      text-indent: .2em;
      border-bottom: solid 1px $color-text;
    }
    .catch-story {
      @include fontsize(33);
      font-family: $font-family-serif;
      margin: 0 0 1em 0;
    }
    .text-story {
      @include fontsize(15);
    }
    .story-photo01 {
      width: auto;
    }
    .story-photo02 {
      width: auto;
    } 
  }
  @media (min-width: $screen-lg-min) { 
    .story-photo01 {
      width: auto;
      position: absolute;
      bottom:-150px;
      left:-80px;
    }
    .story-photo02 {
      width: auto;
      position: absolute;
      bottom:-150px;
      right:-80px;
    }  
  }
}
.sec-story-lebensbaum {
  padding: 3rem 0;
}
.box-lebensbaum{
  @include clearfix();
  .box-image {
    text-align: center;
  }
  .box-info {
    .title-lebensbaum {
      @include fontsize(18);
      font-family: $font-family-serif;
      margin-bottom: .5em;
    }
    .text-lebensbaum {
      @include fontsize(14);
      margin: 0 0 2em 0;
    }
  }
  @media (min-width: $screen-sm-min) {
    .box-info {
      .title-lebensbaum {
        @include fontsize(20);
        text-align: center;
      }
      .text-lebensbaum {
        @include fontsize(15);
        text-align: center;
      }
    } 
  }
}
.col-lebensbaum_left {
  @media (min-width: $screen-sm-min) {
    border-left: solid 1px $color-gray;
    border-right: solid 1px $color-gray;    
  }
}
.col-lebensbaum_right {
  @media (min-width: $screen-sm-min) {
    border-right: solid 1px $color-gray;
  }
}
.card-fruit_story,
.card-5leaf_story {
  background-color: $color-white;
  text-align: center;
  padding: .2em 0;
  margin: 0 0 1rem;
  .card-title {
    @include fontsize(16);
    font-family: $font-family-serif;
    font-weight: bold;
    letter-spacing: .5em;
    text-indent: .5em;
    color:$color-main;
    margin: 0 0 1em 0;
  }
  .card-text {
    @include fontsize(12);
    margin: 0 0 0 0;
  }
  @media (min-width: $screen-sm-min) {
    margin: 0;
    .card-title {
      @include fontsize(18);
    }
    .card-text {
      @include fontsize(14);
    }
  }
}
.card-fruit_story {
  .card-title {
    color:$color-accent;
  }
}
.catch-story-history {
  font-family: $font-family-serif;
  @include fontsize(18);
  color:$color-story-history;
  @media (min-width: $screen-sm-min) {
    @include fontsize(30);
    text-align: center;
  }
}
.sec-story-history {
  @include clearfix();
  padding: 1rem $col-gutter/2;
  position: relative;
  background-color: $color-gray_light;
  .story-history-image_left {
    width: 50%;
    float: left;
  }
  .story-history-image_right {
    float: right;
    width: 50%;
  }
  img {
    vertical-align: bottom;
  }
  @media (min-width: $screen-sm-min) {
    padding: 0;
    .story-history-image_left {
      width: 100%;
      text-align: left;
    }
    .story-history-image_right {
      width: 100%;
      text-align: right;
    }
  }
}
.box-story-history {
  
}
.card-story-history {
  
  .card-title {
    @include fontsize(16);
    background-color: $color-story-history;
    color:$color-white;
    padding: .5rem;
  }
  .card-text {
    @include fontsize(14);
    background-color: $color-white;
    padding: .5rem;
  }
  @media (min-width: $screen-sm-min) {
    .card-title {
      @include fontsize(22);
      background-color: $color-story-history;
      color:$color-white;
      padding: .5em 0;
    }
    .card-text {
      @include fontsize(15);
      background-color: $color-white;
      padding: 3em;
    }
    position: absolute;
    top:50%;
    left:50%;
    margin-left: -300px;
    margin-top: -170px;
    width: 600px;
    text-align: center;
  }
}

.sec-story-end {
  background-image: url(../img/story/bg-story-end.jpg);
  background-position: center center;
  background-size: contain;
  @media (min-width: $screen-sm-min) {
    height: 550px;
  }
}
.catch-story-end {
  font-family: $font-family-serif;
  @include fontsize(18);
  color:$color-story-history;
  padding-bottom: 140px;
  @media (min-width: $screen-sm-min) {
    padding-top: 140px;
    @include fontsize(30);
    text-align: center;
  }
}
