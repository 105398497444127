@charset "UTF-8";
.pos {
  @media (min-width: $screen-sm-min) {
    padding-top:182px;
    margin-top:-182px;
  }
}
.text-white {
  color:$color-white;
}
// 法人のお客さまへ
.sec-page-head {
  background-color: $color-main;
  background-image: url(../img/business/bg-business.jpg);
  background-position: center center;
  padding: 3.25rem 0 3rem 0;
  margin-bottom: 1rem;
  .title-page {
    @include fontsize(23);
    font-weight: normal;
    line-height: 1;
    margin: 0;
    letter-spacing: .5em;
    text-align: center;
    text-indent: .5em;
    color:$color-white;
  }
}
.sec-important_bg {
  margin-top: 3rem;
  padding: 3rem 0;
  background-color: $color-main;  
}

.sec-business,
.sec-factory-head,
.sec-business-rinen,
.sec-business-message,
.sec-business-history,
.sec-business-about {
  padding: 4rem 0;
}
.sec-business_bg,
.sec-business-place {
  padding: 3rem 0;
  background-color: $color-main_superlight;
}
.lang-menu_page-top {
  text-align: center;
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 0;
  .menu-item {
    display: inline-block;
    a {
      display: block;
      @include fontsize(12);
      text-decoration: none;
      color:$color-text;
      background-color:$color-white;
      border: solid 1px $color-gray_dark;
      line-height: 1;
      padding: 1em 1.5em;
      &:hover {
        background-color: $color-gray_dark;
        color:$color-white;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    position: absolute;
    top:-10px;
    right: 0;  
  }
}


.page-article {
  .title-business_h2 {
    @include fontsize(18);
    text-align: center;
    font-weight: bold;
    border-bottom: solid 4px $color-main;
    padding: 1em 0;
    margin-bottom: 3em;
    letter-spacing: .2em;
    text-indent: .2em;
    @media (min-width: $screen-sm-min) {
      @include fontsize(21);
      letter-spacing: .5em;
      text-indent: .5em;
    }
  }
  .title-business_h3 {
    color:$color-main;
    font-weight: bold;
    margin: 1em 0;
    letter-spacing: .2em;
  }
  .title-business_h3_white {
    color:$color-white;
    font-weight: bold;
    margin: 0 0 1.5em;
    letter-spacing: .2em;
  }
  .title-business_h4 {
    font-weight: bold;
    margin: 1em 0;
  }
  .title-business_h3_reverse {
    background-color: $color-main;
    color:$color-white;
    text-align: center;
    font-weight: bold;
    margin: 1em 0;
    letter-spacing: .5em;
    text-indent: .5em;
    padding: .5em 0;
  }
  .catch-message {
    font-weight: bold;
    @media (min-width: $screen-sm-min) {
      @include fontsize(20);
      text-align: center;
      letter-spacing: .1em;
      text-indent: .1em;
      margin: 2em 0;
    }
  }
}

//メッセージ
.box-reason{
  @include clearfix();
  
  .box-image {
    text-align: center;
  }
  .box-info {
    .title-reson {
      @include fontsize(19);
      font-weight: bold;
      padding: 1em 0;
      margin-bottom: 1em;
      border-bottom: solid 1px $color-text;
    }
  }
  @media (min-width: $screen-sm-min) {
    margin-bottom: 1rem;
    .box-image {
      float: left;
      text-align: center;
    }
    .box-info {
      float: left;
      padding: 1em;
    }
  }
}
.card-fruit,
.card-5leaf {
  background-color: $color-white;
  text-align: center;
  padding: 1rem 0;
  margin-bottom: 1rem;
  .card-title {
    font-weight: bold;
    letter-spacing: .5em;
    text-indent: .5em;
    color:$color-main;
  }
}
.card-fruit {
  .card-title {
    color:$color-accent;
  }
}

.box-rinen {
  padding: 1rem 0;
  .box-title {
    position: relative;
    text-align: center;
    @include fontsize(24);
    font-weight: bold;
    border-bottom: solid 1px $color-gray;
    padding: 1em 0;
    margin-bottom: 2em;
    &:after {
      position: absolute;
      bottom:-40px;
      left: 50%;
      margin-left: -20px;
      content: "";
      display: block;
      border-top: 20px solid $color-gray;
      border-right: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid transparent;
    }
  }
  .box-catch {
    @include fontsize(15);
    font-weight: bold;
    letter-spacing: .1em;
    text-indent: .1em;
  }
  .box-list {
    @include fontsize(15);
    font-weight: bold;
    letter-spacing: .1em;
    padding: 0 0 0 2em;
  }
  @media (min-width: $screen-sm-min) {
    .box-catch {
      @include fontsize(20);
      font-weight: normal;
      text-align: center;
    }
    .box-text {
      text-align: center;
    }
    .box-list_wrapper {
      text-align: center;
    }
    .box-list {
      display: inline-block;
      font-weight: normal;
      width: auto;
      @include fontsize(20);
      text-align: left;
    }
  }
}

//会社概要
.box_place {
  @media (min-width: $screen-sm-min) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }  
}
.card-place {
  background-color: $color-white;
  line-height: 1.2;
  .card-place_container {
    padding: 1rem;
  }
  .place-title {
    @include fontsize(15);
    font-weight: bold;
    margin-bottom: 1em;
  }
  .place-photo {
    margin: 1em 0;
  }
  margin-bottom: 1rem;
  @media (min-width: $screen-sm-min) {
    flex: 0 0 350px;
    margin-bottom: 26px;
    .card-place_container {
      padding: 20px;
    }
  }
  @media (min-width: $screen-md-min) {
    flex: 0 0 460px;
    margin-bottom: 40px;
  }
}

#map,
#map2,
#map3,
#map4,
#map5,
#map6 {
  margin-top: 1rem;
  width: 100%;
  height: 240px;
}

.history-list{
  list-style-type: none;
  margin: 0;
  padding: 0;
  .list-item {
    position: relative;
    padding: 1em;
    &:nth-child(even){
      background-color:$color-gray_light;
    }
    .history-date {
      font-weight: bold;
      display: block;
      top:1em;
      left:1em;
    }
    .history-text {
      display: block;
//      margin-left: 11em;
      a{
        color: $color-text;
        &:hover {
          text-decoration: none;
        }
      }
      
    }
    @media (min-width: $screen-sm-min) {
      padding: 1em 0;
      .history-date {
        font-weight: normal;
        position: absolute;
        top:1em;
        left:1em;
      }
      .history-text {
        display: block;
        margin-left: 11em;
      }
    }
  }
}



.business-menu {
  display: block;
  position: inherit;
  .menu {
    @include clearfix();
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: stretch;
//    justify-content: space-around;
    justify-content: center;
    .menu-item {
      a {
        @include fontsize(10);
        position: relative;
        display: block;
        color:$color-text;
        text-decoration: none;
        text-align: center;
        line-height: 1;
        padding: 1em .5em;
        border: solid 1px $color-gray_medium;
        &:hover {
          color:$color-main;
          border: solid 1px $color-main;
        }
      }
      &.current a {
        color:$color-main;
        border: solid 1px $color-main;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    .menu {
      .menu-item {
        width:20%;
        border-right: solid 1px $color-text;
        &:first-child {
          border-left: solid 1px $color-text;
        }
        a {
          @include fontsize(14);
          padding: 1em 0;
          border: 0;
        }
        a:hover {
          border: 0;
        }
        &.current a {
          border: 0;
        }
      }
    }
  }
}
.page-business_about .business-menu .menu .menu-item-business_about a,
.page-business_message .business-menu .menu .menu-item-business_message a,
.page-business_vision .business-menu .menu .menu-item-business_vision a,
.page-business_oem .business-menu .menu .menu-item-business_oem a,
.page-business_contact .business-menu .menu .menu-item-business_contact a{
  color:$color-main;
  border: solid 1px $color-main;
  @media (min-width: $screen-sm-min) {
    border: 0;
  }
}


//ビジョン
.sec-business-vision {
  background-color: $color-gray_light;
  margin-bottom: 4rem;
}
.sec-business-vision_odd {
  background-color: $color-gray_vision_odd;
  margin-bottom: 4rem;
}
//
// ビジョン
//
.card-vision_odd,
.card-vision {
  @include clearfix();
  .card-info {
    padding: 1rem 0;
    .card-title {
      display: flex;
      align-items: center;
      .card-no {
        @include fontsize(80);
        line-height: 1;
        color:$color-gray_vision;
        width:60px;
        text-align: center;
      }
      .card-catch {
        @include fontsize(16);
        font-weight: bold;
        line-height: 1.4;
      }
    }
  }
  .card-image{
    text-align: center;
    padding-bottom: 1rem;
    img {
      vertical-align: bottom;
    }
  }
  @media (min-width: $screen-md-min) {
    .card-info {
      width: 440px;
      padding: 1rem 0;
      float:left;
      .card-title {
        .card-no {
          @include fontsize(100);
          width:70px;
        }
        .card-catch {
          @include fontsize(18);
        }
      }
      .card-text {
        padding-right: 1em;
      }
    }
    .card-image{
      float:right;
      padding: 0;
    }
  }
}
.card-vision_odd {
  @media (min-width: $screen-md-min) {
    .card-info {
      width: 440px;
      padding: 1rem 0;
      float:right;
      .card-title {
        .card-no {
          @include fontsize(100);
          width:70px;
        }
        .card-catch {
          @include fontsize(18);
        }
      }
      .card-text {
        padding-right: 0;
        padding-left: 1em;
      }
    }
    .card-image{
      float: left;
      padding: 0;
    }
  }
}

.card-factory_link {
  display: inline-block;
  text-decoration: none;
  color:$color-text;
  border: solid 1px $color-gray;
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 180px;
  .card-image {
    display: block;
    margin-bottom: 1em;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .card-title {
    position: relative;
    @include fontsize(17);
    font-weight: bold;
    text-align: center;
    display: inline-block;
    padding-right: 25px;
    background-position: right top;
    letter-spacing: .1em;
    &:after {
      position: absolute;
      right: 0;
      top:3px;
      background-image: url(../img/common/icon-link.svg);
      display: inline-block;
      content: "";
      width: 20px;
      height: 20px;
    }
  }
  &:hover {
    border: solid 1px $color-gray_dark;
  }
  @media (min-width: $screen-sm-min) {
    width: 200px;
  }
  @media (min-width: $screen-md-min) {
    width: auto;
    padding: 30px;
    margin: 15px;
  }
}

//
// 工場
//
.title-factory_h2 {
  text-align: center;
  font-weight: bold;
  letter-spacing: .5em;
  text-indent: .5em;
  margin: 1em 0;
}
.title-factory_h3 {
  text-align: center;
  font-weight: bold;
  letter-spacing: .5em;
  text-indent: .5em;
  margin: 0 0 1em 0;
}
.factory-area-list{
  @include clearfix();
  list-style-type: none;
  margin: 0;
  padding: 0;
  .list-item {
    @include fontsize(10);
    display: block;
    float: left;
    line-height: 1;
    width:50%;
    padding: .2em;
    .item-title {
      width: 50%;
      display: inline-block;
      background-color: $color-gray_dark;
      color:$color-white;
      padding: 1em 0;
      text-align: center;
    }
    .item-value {
      width: 50%;
      display: inline-block;
      background-color: $color-gray_light;
      padding: 1em 0;
      text-align: center;
    }
  }
  @media (min-width: $screen-sm-min) {
    .list-item {
      @include fontsize(14);
      width:33.33%;
      padding: .5em;
    }
  }
}

.border-left_factory {
  @media (min-width: $screen-sm-min) {
    border-left: solid 1px $color-gray;
  }
}
.pr-4em_factory {
  @media (min-width: $screen-md-min) {
    padding-right: 4em;
  }
}
.pl-4em_factory {
  @media (min-width: $screen-md-min) {
    padding-left: 4em;
  }
}
.row-equipment_noborder,
.row-equipment {
  border-bottom: solid 1px $color-gray;
  padding: 1rem 0 2rem 0;
  margin-bottom: 2rem;
  .title-business_h4 {
    margin-top: 0 !important;
  }
}
.row-equipment_noborder {
  border-bottom: 0;
}

// 卸・OEM製造
dl.oem-box {
  @include clearfix();
  
  @media (min-width: $screen-sm-min) {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    dt {
      @include fontsize(17);
      flex: 0 0 155px;
      border-bottom: 0;
    }
    dd {
      border-left: solid 1px $color-gray_dark;
      padding: .5em 2em;
      margin: 0;
    }
  }
}
.card-needs {
  margin-bottom: $col-gutter;
  .card-title {
    font-weight: bold;
    background-color: $color-main;
    color:$color-white;
    padding: 1em;
    line-height: 1.2;
  }
  .card-text {
    background-color: $color-white;
    padding: 1em;
  }
}
.card-example {
  border: solid 1px $color-main;
  background-color: $color-white;
  padding: 2rem;
  .list-example {
    @include clearfix();
    padding: 0;
    margin: 0 0 1rem;
    .list-item {
      float:left;
      width: 100%;
      display: block;
      &:before {
        content: "・";
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    .list-example {
      @include clearfix();
      padding: 0;
      margin: 0 0 1rem;
      .list-item {
        float:left;
        width: 50%;
        display: block;
        &:before {
          content: "・";
        }
      }
    } 
  }
  @media (min-width: $screen-md-min) {
    .list-example {
      @include clearfix();
      padding: 0;
      margin: 0 0 1rem;
      .list-item {
        float:left;
        width: 33%;
        display: block;
        &:before {
          content: "・";
        }
      }
    } 
  }
}

.container_qa {
  @media (min-width: $screen-sm-min) {
    padding: 0 3rem;
  }
}
.box-qa {
  padding: 2rem 0;
  border-bottom: dotted 1px $color-gray_dark;
  &:last-child{
    border-bottom: 0;  
  }
  .qa-q {
    position: relative;
    @include fontsize(16);
    font-weight: bold;
    padding-left: 30px;
    &:before {
      position: absolute;
      content: '';
      background-image: url(../img/oem/fig-question.svg);
      width: 20px;
      height: 20px;
      top: 3px;
      left: 0;
    }
  }
  .qa-a {
    padding-left: 30px;
  }
}
